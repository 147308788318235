<template>
  <ResponsiveModal :open="open" @onClose="closeModal" :id="id"
  @isMobile="setIsMobile"
  :loading="loading"
  >
    <template v-slot:modal-content>
      <div class="center">
        <div>
          <p class="title mb-10 text-center">{{$t("Set Viewing Restriction")}}</p>
          <div class="mb-10"
          :class="[isMobile ? '' : 'responsive']"
          >
            <div v-for="(uaRating, index) in uaRatings"
            :key="`ua-rating-key${index}`"

            >
              <UaRating
                @onSelect="onRatingSelect"
                :totalCount="uaRatings.length"
                :uaRating="uaRating"
                :isMobile="isMobile"
                :index="index"
              />
            </div>
          </div>
          <p v-if="formError" class="mb-20 form-error">{{formError}}</p>
          <p v-else class="mb-20"></p>

          <div class="mb-10">
            <button
            :disabled="loading"
            @click="onSubmit" class="button-primary">{{ $t('Save') }}</button>
          </div>
        </div>
      </div>
    </template>
  </ResponsiveModal>
</template>

<script>
import { timeout } from 'q';

export default {
  events: ["onClose", "onActionComplete"],
  props: ["open", "id", "uaRatings", "loading"],
  methods: {
    setIsMobile(isMobile){
      this.isMobile = isMobile;
    },
    closeModal() {
      this.$emit("onClose");
    },

    onSubmit() {
      if(timeout !== null){
        clearTimeout(this.timeout);
      }
      let data = [...this.uaRatings];
      let selected = false;
      let selectedList = [];
      for(let i =0; i< data.length; i++){
        if(data[i].checked){

          selected = true;
          selectedList.push(data[i]);          
        }
      }
      if(!selected){
        this.formError =$t("Please select a U/A Rating")
        this.timeout = setTimeout(() => {
          this.formError ="";
        }, 5000);
        return;
      }
      this.$emit("onActionComplete", selectedList);
    },
    onRatingSelect(index) {
      this.$emit("onSelect", index);
    },
  },

  components: {
    ResponsiveModal: () => import("@/components/utils/ResponsiveModal.vue"),
    UaRating: () => import("@/components/utils/UaRating.vue"),
  },
  data() {
    return {
      formError: "",
      timeout: null,
      isMobile: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./UaRatingsModal.scss"
</style>
